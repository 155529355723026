<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="名称：" label-width="60px" style="width: 310px;">
              <el-input placeholder="请输入名称" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
            </el-form-item>

            <el-form-item label="时间选择：" label-width="90px">
              <div>
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.bengin_time" type="date"
                  placeholder="开始日期" @change="DateChange()">
                </el-date-picker>
                至
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.end_time" type="date" placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </el-form-item>
            <!-- <el-form-item label="是否启用：">
                <el-select
                  v-model="formLabelAlign.is_enable"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in enable_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_addplus('/security/irregularSaveinfo')"
              icon="el-icon-plus">新建</el-button>
            <el-button @click="on_deleteList" icon="el-icon-close">删除</el-button>
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="staus_name" label="状态"></el-table-column>
            <el-table-column prop="place" label="存放地点"></el-table-column>
            <el-table-column prop="in_warehouse_time" label="入库时间"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary"
                  @click="on_addplus('/security/irregularSaveinfo', scope.row.id)">修改</el-link>
                <el-link class="list_sty" type="primary" v-if="scope.row.status == 1"
                  @click="on_takeback(scope.row.id)">领回</el-link>
                <el-link v-else class="list_sty" disabled>领回</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import table_minin from "../../assets/minin/table_minin";
import Bepartment from "../../components/Bepartment";
import {
  irregularindex,
  irregulardelete,
  irregularTakeback
} from "../../assets/request/api";
export default {
  name: "thought_education",
  mixins: [table_minin],
  components: { Bepartment },
  data() {
    return {
      is_extend: true,
      enable_list: [],
      formLabelAlign: {
        name: "",
        is_enable: "",
        bengin_time: "",
        end_time: "",
      },
    };
  },
  created() {
    this.url = irregularindex;
    this.delete_Url = irregulardelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.takebackUrl = irregularTakeback;
    this.way_tableData();
  },
  methods: {
    way_extend(data) {
    },
    //多选删除
    on_takeback(id) {
      let that = this;
      this.$confirm("是否确认领回", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        that.takebackUrl({ id: id }).then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            that.way_tableData();
          }
        });
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
  